export function checkEndpointURL(url: string) {
  if (url === undefined || url === null) {
    throw new Error("api endpoint URL cannot be null or undefined!");
  }
  let result = url;
  const last_slash_idx = result.lastIndexOf("/");
  const last_part_of_string = result.substring(last_slash_idx + 1);
  if (last_part_of_string.indexOf("?") < 0) {
    if (!result.endsWith("/")) result += "/";
  }
  if (!result.startsWith("/")) {
    result = "/" + result;
  }
  return result;
}
